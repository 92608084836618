<template>
  <div class="card border-0 h-100">
<div class="card-body">
  <transition name="route">

    <TriggerSetup  :isLoadingRange="isLoadingRange"
    :rangeList="rangeList" :openCreateModal="openCreateModal" :loadSegments="getSegments" :segments="segments" :isLoading="isLoadingFlow||isLoadingSegments" :isLoadingBtn="isLoadingSave" v-if="isActiveTab=='triggerSetup'" :selected="selectedSegment" @cancel="$emit('cancel')" @done="(selectedSeg)=>{$emit('done',selectedSeg)}"></TriggerSetup>
    <FlowActions v-else-if="isActiveTab=='actionFlows'"  ></FlowActions>
  </transition>
   <!-- <div class="d-flex justify-content-end align-items-center save-btn" v-if="!isTriggerSetupTab" style="margin-top: -38px;">
    <div class="saving" v-if="isLoadingSave">Saving</div>
     <Button  :isLoading="isLoadingSave" @click="$emit('saveEmailFlow')">Save</Button>
    </div> -->
</div>

  </div>
</template>

<script>
// import Button from '../../Layout/Button.vue';
import {mapActions} from 'vuex'
import FlowActions from './Components/FlowActions.vue';
import TriggerSetup from './Components/TriggerSetup.vue';
export default {
components:{
    TriggerSetup,
    FlowActions,
    // Button
},
props:{
  openCreateModal:Function,
  isActiveTab:String,
  isLoadingFlow:Boolean,
  isLoadingSave:Boolean,
  isLoadingRange:Boolean,
  rangeList:Array,
  selectedSegment:{
      type:Object,
      default:()=>({
    segment:{segment_name:'',
    segment_id:''},
    title:''
  })
    }
},
data(){
 return{
  isTriggerSetupTab:false,
  segments:[],
  isLoadingSegments:false,

 }
},
methods:{
  ...mapActions(['fetchData']),
    async  getSegments(selectLastSegment) {
      this.isLoadingSegments=true
      let data=[]
      data = await this.fetchData({
        axiosMethod:'get',
        resource: 'segments',
        method: 'list',
        params:{definitions:false,members:false}
      });
      if(data) {
        this.segments=data.data.segments;
        if(selectLastSegment && this.segments.length>0)
      {
        this.selectedSegment.segment_id=this.segments[this.segments.length-1].id;
       
      }
      }
      this.isLoadingSegments=false

    },
},
watch:{
  isActiveTriggerSetup(newValue){
     this.isTriggerSetupTab=newValue
  }
},
mounted(){
  this.getSegments()
}

}
</script>

<style>
.saving{
  width: 80px;
}
 .saving:after {
            display: inline-block;
            content:'';
            animation: blink 2s infinite ease-in-out; /* Adjust the animation duration as needed */
        }

        @keyframes blink {
            0%, 33% {
                content:'.';
            }
            34%, 66% {
                content:'..';
            }
            67%, 100% {
                content:'...';
            }
        }
        .route-enter-from {
opacity: 0;
transform: translateX(100px)
}
.route-enter-active {
transition: ass 0.3s ease-out;
}
.route-leave-to {
opacity: 0;
transform: translateX(-100px)}
.route-leave-active{
transition: all 0.3s ease-in;}
</style>