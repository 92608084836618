<template>
  <div class="d-flex flex-column mt-5 containerHeight">
    <div class="my-3">
      <div class="flow-header font-size-18 text-black text-capitalize">
        Actions
      </div>
      <draggable
        class="mt-2"
        :list="email_action"
        :group="{ name: 'actions', pull: 'clone', put: false }" @start="toggleFlowComponentDragging" @end="toggleFlowComponentDragging">
        <div class="" v-for="action in email_action" :key="action.id">
          <div class="flow-actions">
            <div class="flow-svg">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 8L12 13L4 8V6L12 11L20 6M20 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V6C22 4.89 21.1 4 20 4Z"
                  fill="#1F1F28" />
              </svg>
            </div>

            <div class="flow-text">Email</div>
          </div>
        </div>
      </draggable>
    </div>
    <!-- Delay Time -->
    <div class="my-3">
      <div class="flow-header font-size-18 text-black text-capitalize">
        Timing
      </div>
      <draggable
        class="mt-2"
        :list="delayAction"
        :group="{ name: 'actions', pull: 'clone', put: false }" @start="toggleFlowComponentDragging" @end="toggleFlowComponentDragging">
        <div class="" v-for="action in delayAction" :key="action.id">
          <div
            class="flow-actions"
            style="border: 1px solid #ffa201; box-shadow: none">
            <div class="flow-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16.2 16.2L11 13V7H12.5V12.2L17 14.9L16.2 16.2Z"
                  fill="#FFA201" />
              </svg>
            </div>

            <div class="flow-text">Delay Time</div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapMutations } from "vuex";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      email_action: [
        {
          email_template_id: "",
          step_type: "email",
          email_template_name: "Email",
          email_template_subject: "Subject",
          is_loading:true
        },
      ],
      delayAction: [
        { step_type: "delay", delay_number: "1", delay_category: "hours",delay_name:"Delay", is_loading:true },
      ],
    };
  },
  methods: {
    ...mapMutations(['toggleFlowComponentDragging']),
    log(evt) {
      console.log(evt);
    },
  },
};
</script>

<style scoped>
.flow-actions {
  padding: 16px;
  align-items: center;
  display: flex;
  gap: 12px;
  cursor:move;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}
.flow-header {
  font-style: normal;
  font-weight: 500;
}
.text-grey {
  color: #73738d !important;
}
.text-black {
  color: #1f1f28;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-14 {
  font-size: 14px;
}
.flow-select {
  outline: none;
  border: none;
  border-bottom: 1px solid #b2afb6;
  width: 100%;
  padding: 6px 0px;
}
.text-quizell {
  color: #4d1b7e;
}
.border-quizell {
  border: 1px solid #4d1b7e;
}
.border-quizell:not(:disabled):not(.disabled):active {
  background: #4d1b7e !important;
  color: white;
  box-shadow: none !important;
}

.containerHeight{
  height: calc(100% - 56px);
}
</style>
